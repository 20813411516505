import React, { useEffect, useState } from 'react';
import { restApi } from '../../../../common/api';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import LectureSearchItem from '../../../../components/LectureSearchItem';

export default function LectureScheduleForm() {

  const navigate = useNavigate();

  const { state } = useLocation();
  //  console.log(state.item)

  const query = useParams();
  //  console.log(query)

  const { principal } = useSelector((s) => s.auth, shallowEqual);
  const { lectureId: originLecId } = useSelector((x) => x.common, shallowEqual);
  const { codes, semesterId } = useSelector((s) => s.common, shallowEqual);

  const [lectureId, setLecId] = useState(originLecId);

  const [typeManual, setTypeManual] = useState(query.id ? (state.item.timeCode == null || state.item.timeCode == 0) ?  true : false : false);
  const [typeManual2, setTypeManual2] = useState(false);

  const [timeData, setTimeData] = useState();

  const [formData, setFormData] = useState({
    session: query.id ? state.item.session : undefined,
    place: query.id ? state.item.place : undefined,
    date: query.id ? state.item.date : undefined,
    newDate: undefined,
    timeCode: query.id ? state.item.timeCode : undefined,
    newTimeCode: undefined,
    timeTitle: query.id ? state.item.timeTitle : undefined,
    newTimeTitle: undefined,
    startTime: query.id ? state.item.timeCode == null ? state.item.startTime : undefined: undefined,
    endTime: query.id ? state.item.timeCode == null ? state.item.endTime : undefined :undefined,
    newStartTime:undefined,
    newEndTime: undefined,
    noticeYN: query.id ? true : false,
    etc: query.id ? state.item.etc : undefined,
    writer: query.id ? state.item.writer : principal.name,
    semesterId: semesterId,
    statusText: query.id ? state.item.statusText == "취소" ? "취소" : '변경' : '',
    newStatusText: query.id ? "변경" : "",
    changeDate: false,
    cancelClicked: query.id ? state.item.statusText == "취소" ? true : false : false,
    color : query.id ? state.item.color : undefined,
  });

  useEffect(() => {
    const loadData = async () => {
      const { data } = await restApi.get(`/lectures/timeSchedule`);
      setTimeData(data);
    };
    loadData().catch(console.warn);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (query.id) {
      if (!formData.cancelClicked) {
        if (formData.changeDate && !formData.newDate) {
          alert('변경 날짜를 선택 해 주세요.');
          return;
        }
  
        if (!typeManual2 && !formData.newTimeCode && formData.changeDate) {
          alert('시간을 선택 해 주세요.');
          return;
        }
  
        if (typeManual2 && !formData.newStartTime && !formData.newEndTime) {
          alert('시간을 입력 해 주세요.');
          return;
        }
        if (!formData.session) {
          alert('회차을 입력 해 주세요.');
          return;
        }
        if (!formData.place) {
          alert('장소을 입력 해 주세요.');
          return;
        }
        
      }
    } else {
      if (!formData.color) {
        alert('색상을 선택 해 주세요.');
        return;
      }

      if (!lectureId) {
        alert('교육을 선택 해 주세요.');
        return;
      }

      if (!formData.date) {
        alert('날짜를 선택 해 주세요.');
        return;
      }

      if (!typeManual && !formData.timeCode) {
        alert('시간을 선택 해 주세요.');
        return;
      }

      if (typeManual && !formData.startTime && !formData.endTime) {
        alert('시간을 입력 해 주세요.');
        return;
      }
      if (!formData.session) {
        alert('회차을 입력 해 주세요.');
        return;
      }

      if (!formData.place) {
        alert('장소을 입력 해 주세요.');
        return;
      }
    }



    if (query.id) {
      const res = await restApi.put(`/lectures/lectureSchedule/${query.id}`, formData);
      alert('수정되었습니다.');
    } else {
      const res = await restApi.post(`/lectures/lectureSchedule/${lectureId}`, formData);
      alert('등록되었습니다.');
    }
    await navigate(`/lms/lectures/schedule`);
  };


  return (
    <form className="row" onSubmit={handleSubmit}>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <div className="content-wrapper container-xxl p-0">
        {/* header s */}
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="row breadcrumbs-top">
              <div className="col-12">
                <h2 className="content-header-title float-start mb-0">시간표</h2>
                <div className="breadcrumb-wrapper">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">시간표</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* // header e */}
        {/* con s */}
        <div className="content-wrapper container-xxl p-0">
          <form className="row" onSubmit={handleSubmit}>
            <div className="col-12">
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-bordered visual-box">
                    <colgroup>
                      <col style={{ width: '11rem' }} />
                      <col />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th>
                          <label className="form-label" htmlFor="blog-edit-title">
                            색상
                          </label>
                        </th>
                        <td scope="col" colSpan={1} className="p-schedule-add">
                          <div className="checks">
                            <input
                              type="radio"
                              id="cal-c-red"
                              className="cal-c-red"
                              name="color"
                              title="연한 빨강색"
                              checked={formData?.color === 'red'}
                              onClick={() => {
                                setFormData((x) => ({ ...x, color: 'red' }));
                              }}
                            />
                            <label htmlFor="cal-c-red" />
                            <input
                              type="radio"
                              id="cal-c-orange"
                              className="cal-c-orange"
                              name="color"
                              title="연한 주황색"
                              checked={formData?.color === 'orange'}
                              onClick={() => {
                                setFormData((x) => ({ ...x, color: 'orange' }));
                              }}
                            />
                            <label htmlFor="cal-c-orange" />
                            <input
                              type="radio"
                              id="cal-c-yellow"
                              className="cal-c-yellow"
                              name="color"
                              title="연한 노란색"
                              checked={formData?.color === 'yellow'}
                              onClick={() => {
                                setFormData((x) => ({ ...x, color: 'yellow' }));
                              }}
                            />
                            <label htmlFor="cal-c-yellow" />
                            <input
                              type="radio"
                              id="cal-c-green"
                              className="cal-c-green"
                              name="color"
                              title="연한 초록색"
                              checked={formData?.color === 'green'}
                              onClick={() => {
                                setFormData((x) => ({ ...x, color: 'green' }));
                              }}
                            />
                            <label htmlFor="cal-c-green" />
                            <input
                              type="radio"
                              id="cal-c-blue"
                              className="cal-c-blue"
                              name="color"
                              title="연한 파랑색"
                              checked={formData?.color === 'blue'}
                              onClick={() => {
                                setFormData((x) => ({ ...x, color: 'blue' }));
                              }}
                            />
                            <label htmlFor="cal-c-blue" />
                            <input
                              type="radio"
                              id="cal-c-purple"
                              className="cal-c-purple"
                              name="color"
                              title="연한 보라색"
                              checked={formData?.color === 'purple'}
                              onClick={() => {
                                setFormData((x) => ({ ...x, color: 'purple' }));
                              }}
                            />
                            <label htmlFor="cal-c-purple" />
                            <input
                              type="radio"
                              id="cal-c-white"
                              className="cal-c-white"
                              name="color"
                              title="흰색"
                              checked={formData?.color === 'white'}
                              onClick={() => {
                                setFormData((x) => ({ ...x, color: 'white' }));
                              }}
                            />
                            <label htmlFor="cal-c-white" />
                            <input
                              type="radio"
                              id="cal-c-grey"
                              className="cal-c-grey"
                              name="color"
                              title="회색"
                              checked={formData?.color === 'grey'}
                              onClick={() => {
                                setFormData((x) => ({ ...x, color: 'grey' }));
                              }}
                            />
                            <label htmlFor="cal-c-grey" />
                          </div>
                        </td>
                      </tr>
                      {!['TEACHER', 'STAFF', 'EXAMSTAFF'].includes(principal?.type) && (
                        <tr>
                          <th>
                            <label className="form-label">학기/교육 *</label>
                          </th>
                          <td>
                            {query.id ? state.item.semesterYear + "년  -" + codes?.map?.[state.item.semesterSession] + " / " + state.item.lectureTitle : <LectureSearchItem value={lectureId} onChange={setLecId} />
                            }
                          </td>
                        </tr>
                      )}
                      <tr>
                        <th>
                          <label className="form-label">날짜/시간 *</label>
                        </th>
                        <td>
                          <div className="d-flex align-items-center">
                            <input
                              type="date"
                              className="form-control flatpickr-basic flatpickr-input me-25"
                              placeholder="YYYY-MM-DD"
                              value={formData.date}
                              disabled={query.id ? true : false}
                              style={{ width: 140 }}
                              onChange={(e) => {
                                const v = e.target.value;
                                setFormData((x) => ({ ...x, date: v }));
                              }}
                            />
                            {!typeManual ?
                              <select
                                className="form-select w-auto pe-3"
                                value={formData.timeCode}
                                disabled={query.id ? (formData.changeDate || formData.cancelClicked) : false}
                                onChange={(e) => {
                                  const v = e.target.value;
                                  if (query.id) {
                                    setFormData((x) => ({ ...x, timeCode: v,newTimeCode: v, newTimeTitle: e.target.options[e.target.selectedIndex].text }));
                                  } else {
                                    setFormData((x) => ({ ...x, timeCode: v, timeTitle: e.target.options[e.target.selectedIndex].text }));
                                  }
                                }}
                              >
                                <option value={''} key={0}>선택해주세요</option>
                                {timeData?.map((item) => (
                                  <option value={item.code} key={item.code}>
                                    {item.title}
                                  </option>
                                ))}
                              </select> :
                              <div className="d-flex align-items-center">
                                <input
                                  type="text"
                                  className="form-control flatpickr-basic flatpickr-input"
                                  placeholder="HH:MM"
                                  style={{ width: 70 }}
                                  defaultValue={query.id ? formData.timeCode == null ? formData.startTime.substring(0, 5) : '00:00' : '00:00'}
                                  disabled={query.id ? (formData.changeDate || formData.cancelClicked) : false}
                                  onChange={(e) => {
                                    const v = e.target.value;
                                    if (query.id) {
                                      setFormData((x) => ({ ...x, newTimeCode: 0, newStartTime: v }));
                                    } else {
                                      setFormData((x) => ({ ...x, timeCode: 0, startTime: v }));
                                    }
                                  }
                                  }
                                />
                                <span className={'ms-25 me-25'}>~</span>
                                <input
                                  type="text"
                                  className="form-control flatpickr-basic flatpickr-input"
                                  placeholder="HH:MM"
                                  style={{ width: 70 }}
                                  defaultValue={query.id ? formData.timeCode == null ? formData.endTime.substring(0, 5) : '00:00' : '00:00'}
                                  disabled={query.id ? (formData.changeDate || formData.cancelClicked) : false}
                                  onChange={(e) => {
                                    const v = e.target.value;
                                    if (query.id) {
                                      setFormData((x) => ({ ...x, newTimeCode: 0, newEndTime: v }));
                                    } else {
                                      setFormData((x) => ({ ...x, timeCode: 0, endTime: v }));
                                    }
                                  }
                                  }
                                />
                              </div>
                            }
                            {
                              query.id ?
                                <div>
                                  <input
                                    className="form-check-input ms-50"
                                    id='ch00'
                                    type="checkbox"
                                    defaultChecked={formData.changeDate}
                                    checked={formData.changeDate}
                                    onClick={() => {

                                      setFormData((x) => ({ ...x, cancelClicked: formData.cancelClicked ? !formData.cancelClicked : false, 
                                                                  changeDate: !formData.changeDate, 
                                                                  noticeYN: true, 
                                                                  statusText: query.id ? formData.noticeYN ? !formData.changeDate ? "변경" : "변경" : "" : formData.noticeYN ? "" : "", 
                                                                  // newStatusText: formData.changeDate ? "변경" : "변경 (*날짜변경 : " + formData.date + ")",
                                                                  newStatusText:  "변경",
                                                                  timeCode: state.item.timeCode,
                                                                  newTimeCode : undefined,
                                                                  newTimeTitle : undefined 
                                                                }));
                                    }}
                                  />
                                  <label className="form-check-label ms-50" htmlFor="ch00">
                                    날짜 변경
                                  </label>
                                  <input
                                    className="form-check-input ms-50"
                                    id='ch01'
                                    type="checkbox"
                                    defaultChecked={formData.cancelClicked}
                                    checked={formData.cancelClicked}
                                    onClick={() => {
                                      setFormData((x) => ({ ...x, timeCode: state.item.timeCode, 
                                                                  cancelClicked: !formData.cancelClicked, 
                                                                  changeDate: formData.changeDate ? !formData.changeDate : false, 
                                                                  noticeYN: true, 
                                                                  statusText: query.id ? formData.noticeYN ? !formData.cancelClicked ? "취소" : "변경" : "" : formData.noticeYN ? "" : "", 
                                                                  newStatusText: !formData.cancelClicked ? "취소" : "변경",
                                                                  newTimeCode : undefined,
                                                                  newTimeTitle : undefined }));
                                    }}
                                  />
                                  <label className="form-check-label ms-50" htmlFor="ch01">
                                    취소
                                  </label>
                                </div> :
                                <div>
                                  <input
                                    className="form-check-input ms-50"
                                    id='ch02'
                                    type="checkbox"
                                    defaultChecked={typeManual}
                                    onClick={() => {
                                      setTypeManual(!typeManual);
                                      setFormData((x) => ({ ...x, timeCode: 0 }));
                                    }}
                                  />
                                  <label className="form-check-label ms-50" htmlFor="ch02">
                                    직접 입력
                                  </label>
                                </div>
                            }
                          </div>
                          {formData.changeDate && (
                            <>
                              <div className="align-items-center d-flex mt-50" style={{ height: 40 }}>
                                <label className="text-center m-50" style={{ color: 'red' }}>[날짜변경]</label>
                                <input
                                  type="date"
                                  className="form-control flatpickr-basic flatpickr-input me-25"
                                  placeholder="YYYY-MM-DD"
                                  style={{ width: 140 }}
                                  onChange={(e) => {
                                    const v = e.target.value;
                                    setFormData((x) => ({ ...x, newDate: v }));
                                  }}
                                />
                                {typeManual2 ?
                                  <div className="d-flex align-items-center">
                                    <input
                                      type="text"
                                      className="form-control flatpickr-basic flatpickr-input"
                                      placeholder="HH:MM"
                                      style={{ width: 70 }}
                                      defaultValue={'00:00'}
                                      onChange={(e) => {
                                        const v = e.target.value;
                                        setFormData((x) => ({ ...x, newTimeCode: 0, newStartTime: v }));
                                      }
                                      }
                                    />
                                    <span className={'ms-25 me-25'}>~</span>
                                    <input
                                      type="text"
                                      className="form-control flatpickr-basic flatpickr-input"
                                      placeholder="HH:MM"
                                      style={{ width: 70 }}
                                      defaultValue={'00:00'}
                                      onChange={(e) => {
                                        const v = e.target.value;
                                        setFormData((x) => ({ ...x, newTimeCode: 0, newEndTime: v }));
                                      }
                                      }
                                    />
                                  </div> : <select
                                    className="form-select w-auto pe-3"
                                    onChange={(e) => {
                                      const v = e.target.value;
                                      setFormData((x) => ({ ...x, newTimeCode: v, newTimeTitle: e.target.options[e.target.selectedIndex].text }));
                                    }}
                                  >
                                    <option value={''} key={0}>선택해주세요</option>
                                    {timeData?.map((item) => (
                                      <option value={item.code} key={item.code}>
                                        {item.title}
                                      </option>
                                    ))}
                                  </select>}
                                <input
                                  className="form-check-input ms-50"
                                  id='ch03'
                                  type="checkbox"
                                  defaultChecked={typeManual2}
                                  onClick={() => {
                                    setTypeManual2(!typeManual2);
                                  }}
                                />
                                <label className="form-check-label ms-50" htmlFor="ch03">
                                  직접 입력
                                </label>
                              </div>
                            </>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">회차 *</label>
                        </th>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name={'session'}
                            defaultValue={formData.session}
                            disabled={query.id ? formData.cancelClicked : false}
                            onChange={(e) => {
                              const v = e.target.value;
                              setFormData((x) => ({ ...x, session: v }));
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">장소 *</label>
                        </th>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name={'place'}
                            defaultValue={formData.place}
                            disabled={query.id ? formData.cancelClicked : false}
                            onChange={(e) => {
                              const v = e.target.value;
                              setFormData((x) => ({ ...x, place: v }));
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">비고</label>
                        </th>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name={'etc'}
                            defaultValue={formData.etc}
                            onChange={(e) => {
                              const v = e.target.value;
                              setFormData((x) => ({ ...x, etc: v }));
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">공지 노출 *</label>
                        </th>
                        <td className="d-flex align-items-center">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="ra01"
                              defaultChecked={formData.noticeYN}
                              checked={formData.noticeYN}
                              onChange={(e) => {
                                setFormData((x) => ({ ...x, noticeYN: true, statusText: query.id ? !formData.noticeYN ? "변경" : "" : !formData.noticeYN ? "" : "" }));
                              }}
                            />
                            <label className="form-check-label" htmlFor="ra01">
                              Y
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="ra02"
                              defaultChecked={!formData.noticeYN}
                              checked={!formData.noticeYN}
                              disabled={query.id ? (formData.changeDate || formData.cancelClicked) : false}
                              onChange={(e) => {
                                setFormData((x) => ({ ...x, noticeYN: false, statusText: query.id ? !formData.noticeYN ? "변경" : "" : !formData.noticeYN ? "" : "" }));
                              }}
                            />
                            <label className="form-check-label" htmlFor="ra02">
                              N
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">이력표기</label>
                        </th>
                        <td>
                          {formData.statusText}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row mt-50">
                <div className="col-md-6">
                  {query.id && <label>※ 수업 날짜를 변경한 경우, 사용자 하면에 취소 이력이 노출됩니다.</label>}
                </div>
                <div className="col-md-6 txt-r">
                  <button type="submit" className="btn btn-primary me-1 waves-effect waves-float waves-light" onClick={handleSubmit}>
                    {query.id ? "수정" : "등록"}
                  </button>
                  <button
                    type="reset"
                    className="btn btn-outline-secondary waves-effect"
                    onClick={() => {
                      window.history.back();
                    }}
                  >
                    취소
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        {/* // con e */}
      </div>
    </form>
  );
};
