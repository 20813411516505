import dayjs from 'dayjs';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { restApi } from '../../../common/api';
import BoardAttachSingle from '../../../components/boards/BoardAttachSingle';
import { shallowEqual, useSelector } from 'react-redux';
import Modal from '../../../components/Modal';
import Postcode from '@actbase/react-daum-postcode';
import { useLoading } from '../../../nav/AppContainer';
import { validPassword } from '../../../common/utils';

export default function LmsAccountsWrite() {
  const { codes } = useSelector((s) => s.common, shallowEqual);
  const navigate = useNavigate();
  const query = useParams();

  const attacher = useRef();
  const [findPostcode, setFindPostcode] = useState();
  const [crews, setCrews] = useState([]);
  const [checkedList, setCheckedList] = useState([]);

  const [formData, setFormData] = useState({
    attending: undefined,
    address: '',
    addressDetails: '',
    area: [''],
    bio: '',
    birthday: '',
    companyAddress: '',
    companyAddressDetails: '',
    companyBio: '',
    companyName: '',
    companyPosition: '',
    companyTeam: '',
    companyZipcode: '',
    email: '',
    gender: undefined,
    imageId: null,
    isForeign: undefined,
    lunar: undefined,
    mobilePhone: '',
    name: '',
    nowPassword: '',
    password: '',
    period: '',
    phone: '',
    state: undefined,
    type: undefined,
    userId: '',
    workCorpType: '',
    workJobType: '',
    workPlace: '',
    zipcode: '',
    hideMe: false,
    isLoa: false
  });
  const regions = useMemo(() => {
    const n = codes?.tree?.AREA?.find((x) => x.code == formData.region1);
    return n?.extra1 ? JSON.parse(n.extra1) : [];
  }, [codes?.tree?.AREA, formData.region1]);

  useEffect(() => {
    const loadCrew = async () => {
      const { data } = await restApi.get(`/crews`);
      setCrews(data.content);
    };

    loadCrew().catch(console.warn);

    if (query?.id) {
      const loadData = async () => {
        const { data } = await restApi.get(`/accounts/${query.id}/bo`);
        const init = {
          emailFront: data.email?.split('@')[0],
          emailBack: data.email?.split('@')[1],
          birthdayYear: data.birthday?.split?.('-')?.[0] || '1990',
          birthdayMonth: data.birthday?.split?.('-')?.[1] || '01',
          birthdayDay: data.birthday?.split?.('-')?.[2] || '01',
          lunar: data.lunar ? '음력' : '양력',
          phone1: data.phone?.split?.('-')?.[0],
          phone2: data.phone?.split?.('-')?.[1],
          phone3: data.phone?.split?.('-')?.[2],
          mobilePhone1: data.mobilePhone?.split?.('-')?.[0],
          mobilePhone2: data.mobilePhone?.split?.('-')?.[1],
          mobilePhone3: data.mobilePhone?.split?.('-')?.[2],
          gender: data?.gender || 'MALE',
          idChecked: true,
          attending: data.attending ? 'true' : 'false',
          state: data.state ? 'true' : '',
          hideMe: data.hideMe ? 'true' : '',
          isLoa: data.isLoa
        };
        init.region1 = codes?.tree?.AREA?.find((x) => x.label === data?.area?.[0])?.code;
        init.region2 = data.area?.[1];
        setFormData({ ...data, ...init });
      };
      loadData().catch(console.warn);
    }
  }, [query?.id]);

  const handleOnchange = (e) => {
    console.log(e)
    const v = e.target.value;
    console.log(v)
    setFormData((x) => ({
      ...x,
      [e.target.name]: v,
    }));
    const v2 = e.target.value2;
    console.log(v2)
    setFormData((x) => ({
      ...x,
      [e.target.name2]: v2,
    }));
  };

  const [checkId, setCheckId] = useState();
  const handleCheckId = () => {
    if (checkId) {
      alert('사용가능한 아이디입니다.');
      setFormData((x) => {
        return { ...x, idChecked: true };
      });
    } else {
      setFormData((x) => {
        return { ...x, userId: '' };
      });
      alert('이미 사용중인 아이디입니다.');
    }
  };

  useEffect(() => {
    const loadCheckId = async () => {
      const result = await restApi.get('/accounts/check-id', { params: { userId: formData.userId } });
      setCheckId(result.data);
    };
    loadCheckId().catch(console.warn);
  }, [formData.userId]);

  const [loading, setLoading] = useLoading();
  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(formData)

    const payload = {
      ...formData,
      email: [e.target.emailFront.value, formData.emailBack].join('@'),
      phone: [e.target.phone1.value, e.target.phone2.value, e.target.phone3.value].join('-'),
      mobilePhone: [e.target.mobilePhone1.value, e.target.mobilePhone2.value, e.target.mobilePhone3.value].join('-'),
      attending: formData.attending === 'true' ? true : false,
      state: formData.state === 'true' ? true : false,
      hideMe: formData.hideMe === 'true' ? true : false,
    };

    if (formData.isForeign) {
      const n = codes?.tree?.AREA?.find((x) => x.code == formData.region1);
      payload.area = [n?.label, formData.region2].filter((x) => !!x);
    } else {
      payload.area = [];
    }

    if (!payload.name) {
      alert('이름을 입력해주세요.');
      return;
    }

    if (!payload.type) {
      alert('분류를 선택해주세요.');
      return;
    }

    if (!payload.userId) {
      alert('아이디를 입력해주세요.');
      return;
    }

    if (!payload.idChecked) {
      alert('아이디 중복확인을 해주세요.');
      return;
    }

    if (e.target.password.value) {
      if (!validPassword(e.target.password.value)) {
        alert('8~12자의 숫자와 영문자와 특수문자 조합으로 비밀번호를 변경하십시오.');
        return;
      }
      if (e.target.password.value !== e.target.passwordConfirm.value) {
        alert('비밀번호가 일치하지 않습니다.');
        return;
      }
      payload.password = e.target.password.value;
    }

    const bd = [e.target.birthdayYear.value, e.target.birthdayMonth.value, e.target.birthdayDay.value].filter(
      (x) => !!x,
    );
    if (bd.length === 3) {
      payload.birthday = bd.join('-');
    } else {
      alert('생일을 정확히 입력해주세요.');
      return;
    }

    if (!payload.lunar) {
      alert('생일 양력/음력을 입력해주세요.');
      return;
    } else {
      const lunar = formData.lunar === '음력' ? true : false;
      payload.lunar = lunar;
    }

    if (!payload.gender) {
      alert('성별을 입력해주세요.');
      return;
    }

    if (!formData.emailFront || !formData.emailBack) {
      alert('메일주소를 정확히 입력해주세요.');
      return;
    }

    if (!formData.mobilePhone1 || !formData.mobilePhone2 || !formData.mobilePhone3) {
      alert('휴대폰 번호를 정확히 입력해주세요.');
      return;
    }

    setLoading(true);
    try {
      payload.imageId = await attacher.current.getFileId();

      if (query?.id) {
        await restApi.put(`/accounts/${query?.id}`, payload);
        alert('저장되었습니다.');
        await navigate(`/lms/accounts/${query?.id}`);
      } else {
        await restApi.post(`/accounts`, payload);
        alert('등록되었습니다.');
        await navigate('/lms/accounts');
      }
    } catch (e) {
      alert(e);
    }
    setLoading(false);
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">계정/권한관리</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">계정/권한관리</li>
                  <li className="breadcrumb-item active">계정관리</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}
      <ul className="nav nav-tabs" role="tablist">
        <li className="nav-item">
          <Link className="nav-link active" role="tab" to="#n">
            계정관리
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" role="tab" to="#n">
            권한설정
          </Link>
        </li>
      </ul>
      {/* Bordered table start s */}
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              {/* Bordered table start */}
              <h3 className="mb-2">계정등록/수정</h3>
              <h4 className="text-primary">필수 입력정보</h4>
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-bordered visual-box">
                    <colgroup>
                      <col style={{ width: '11rem' }} />
                      <col />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th>
                          <label className="form-label">
                            이름<span className="require">*</span>
                          </label>
                        </th>
                        <td>
                          <div className="d-flex align-items-center">
                            <input
                              type="text"
                              name="name"
                              value={formData.name}
                              className="form-control"
                              style={{ width: 200 }}
                              onChange={handleOnchange}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          분류<span className="require">*</span>
                        </th>
                        <td>
                          <div className="d-flex align-items-center">
                            <select
                              className="form-select w-auto pe-3 me-75"
                              name="type"
                              value={formData.type}
                              onChange={handleOnchange}
                              disabled={!!query?.id}
                            >
                              <option value="">선택</option>
                              <option value="USER">원우</option>
                              <option value="TEACHER">교수</option>
                              <option value="STAFF">튜터</option>
                              <option value="MANAGER">행정실</option>
                              <option value="ADMIN">지원실</option>
                              <option value="CP">cp</option>
                              <option value="EXAMSTAFF">시험감독관</option>
                            </select>
                            {formData.type == 'USER' && (
                              <>
                                <select
                                  className="form-select w-auto pe-3"
                                  name="attending"
                                  name2="status"
                                  value={formData.attending}
                                  onChange={handleOnchange}
                                >
                                  <option value="true">재학생</option>
                                  <option value="false">졸업생</option>
                                </select>
                                <span className="ms-1 me-1">IMBA</span>
                                <input
                                  type="number"
                                  className="form-control"
                                  style={{ width: 80 }}
                                  name="period"
                                  value={formData.period}
                                  onChange={handleOnchange}
                                />
                                <span className="ms-25 me-1">기</span>
                                <input
                                  className="form-check-input me-25"
                                  id='ch00'
                                  type="checkbox"
                                  defaultChecked={formData.isLoa}
                                  checked={formData.isLoa}
                                  onClick={() => {
                                    setFormData((x) => ({ ...x, isLoa: !x.isLoa }));
                                  }}
                                />
                                <label className="form-check-label ms-25" htmlFor="ch00">
                                  휴학생
                                </label>
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          학번(아이디)<span className="require">*</span>
                        </th>
                        <td>
                          <div className="d-flex align-items-center">
                            {query.id ? (
                              <span>{formData.userId}</span>
                            ) : (
                              <>
                                <input
                                  type="text"
                                  name="userId"
                                  value={formData.userId}
                                  onChange={handleOnchange}
                                  className="form-control"
                                  style={{ width: 200 }}
                                />
                                <button
                                  type="button"
                                  className="btn btn-primary waves-effect w-auto ms-75"
                                  onClick={() => handleCheckId()}
                                >
                                  중복확인
                                </button>
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>비밀번호</th>
                        <td>
                          <div className="d-flex align-items-center">
                            <input
                              type="password"
                              name="password"
                              defaultValue={formData.password}
                              className="form-control"
                              style={{ width: 200 }}
                            />
                            <span className="text-danger ms-1">
                              ※ 8~12자의 숫자와 영문자와 특수문자 조합으로 비밀번호를 변경하십시오.
                              <br />
                              새로운 비밀번호를 입력하시면 비밀번호가 변경됩니다.
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>비밀번호 확인</th>
                        <td>
                          <div className="d-flex align-items-center">
                            <input
                              type="password"
                              className="form-control"
                              style={{ width: 200 }}
                              name="passwordConfirm"
                              defaultValue={formData.passwordConfirm}
                            />
                            {/* <span className="text-primary ms-1">비밀번호가 일치합니다.</span> */}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>사진</th>
                        <td className="d-flex">
                          <div className="d-flex flex-column">
                            <BoardAttachSingle ref={attacher} defaultValue={formData.imageId} />
                            {/* <div>
                              <input className="form-control" type="file" multiple="" />
                            </div> */}
                            <span className="text-primary mt-50">
                              * 사진은 상반신 정면 얼굴 사진으로 jpg, png 파일 1mb 이하로 등록해주세요.
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">
                            생년월일<span className="require">*</span>
                          </label>
                        </th>
                        <td>
                          <div className="d-flex align-items-center">
                            <select
                              className="form-select w-auto pe-3"
                              name="birthdayYear"
                              onChange={handleOnchange}
                              value={formData.birthdayYear}
                              defaultValue={formData.birthdayYear}
                            >
                              <option value="">선택</option>
                              {[...new Array(100)].map((_, index) => {
                                const value = new Date().getFullYear() - 20 - index;
                                return (
                                  <option key={`Birth-Year-${index}`} value={value}>
                                    {value}
                                  </option>
                                );
                              })}
                            </select>
                            <span className="ms-25 me-1">년</span>
                            <select
                              className="form-select w-auto pe-3"
                              name="birthdayMonth"
                              onChange={handleOnchange}
                              value={formData.birthdayMonth}
                              defaultValue={formData.birthdayMonth}
                            >
                              <option value="">선택</option>
                              {[...new Array(12)].map((_, index) => {
                                const value = index + 1;
                                return (
                                  <option key={`Birth-Month-${index}`} value={String(value).padStart(2, '0')}>
                                    {value}
                                  </option>
                                );
                              })}
                            </select>
                            <span className="ms-25 me-1">월</span>
                            <select
                              className="form-select w-auto pe-3"
                              name="birthdayDay"
                              onChange={handleOnchange}
                              value={formData.birthdayDay}
                              defaultValue={formData.birthdayDay}
                            >
                              <option value="">선택</option>
                              {[
                                ...new Array(
                                  dayjs(
                                    `${formData.birthdayYear}-${formData.birthdayMonth}`,
                                    'YYYY-MM',
                                  ).daysInMonth() || 0,
                                ),
                              ].map((_, index) => {
                                return (
                                  <option key={`Birth-Month-${index}`} value={String(index + 1).padStart(2, '0')}>
                                    {index + 1}
                                  </option>
                                );
                              })}
                            </select>
                            <span className="ms-25 me-2">일</span>
                            <div className="d-flex align-items-center" onChange={handleOnchange}>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="lunar"
                                  value={'양력'}
                                  checked={formData.lunar === '양력'}
                                  // defaultChecked={formData.lunar}
                                  id={'inlineRadio1'}
                                />
                                <label className="form-check-label" htmlFor="inlineRadio1">
                                  양력
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="lunar"
                                  checked={formData.lunar === '음력'}
                                  value={'음력'}
                                  id={'inlineRadio2'}
                                />
                                <label className="form-check-label" htmlFor="inlineRadio2">
                                  음력
                                </label>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">
                            성별<span className="require">*</span>
                          </label>
                        </th>
                        <td>
                          <div className="d-flex align-items-center" onChange={handleOnchange}>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="inlineRadio221"
                                name="gender"
                                value="MALE"
                                checked={formData.gender == 'MALE'}
                              />
                              <label className="form-check-label" htmlFor="inlineRadio221">
                                남
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="inlineRadio222"
                                name="gender"
                                value="FEMALE"
                                checked={formData.gender == 'FEMALE'}
                              />
                              <label className="form-check-label" htmlFor="inlineRadio222">
                                여
                              </label>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">
                            이메일<span className="require">*</span>
                          </label>
                        </th>
                        <td>
                          <div className="d-flex align-items-center">
                            <input
                              type="text"
                              className="form-control"
                              style={{ width: 200 }}
                              name="emailFront"
                              value={formData.emailFront}
                              onChange={handleOnchange}
                            />
                            <span className="mx-75">@</span>
                            <input
                              type="text"
                              className="form-control"
                              style={{ width: 200 }}
                              name="emailBack"
                              value={formData.emailBack}
                              onChange={handleOnchange}
                            />
                            <select
                              className="form-select w-auto pe-3 ms-75"
                              value={formData.emailBack}
                              name={'emailBack'}
                              onChange={handleOnchange}
                            >
                              <option value="">직접입력</option>
                              {codes?.tree?.MAIL?.map((code) => (
                                <option value={code.label} key={code.code}>
                                  {code.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">전화번호</label>
                        </th>
                        <td>
                          <div className="row mx-0">
                            <input
                              type="text"
                              className="form-control"
                              style={{ width: 100 }}
                              name="phone1"
                              value={formData.phone1}
                              onChange={handleOnchange}
                            />
                            <span className="hipen">-</span>
                            <input
                              type="text"
                              className="form-control"
                              style={{ width: 100 }}
                              name="phone2"
                              value={formData.phone2}
                              onChange={handleOnchange}
                            />
                            <span className="hipen">-</span>
                            <input
                              type="text"
                              className="form-control"
                              style={{ width: 100 }}
                              name="phone3"
                              value={formData.phone3}
                              onChange={handleOnchange}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">
                            휴대폰번호<span className="require">*</span>
                          </label>
                        </th>
                        <td>
                          <div className="row mx-0">
                            <input
                              type="text"
                              className="form-control"
                              style={{ width: 100 }}
                              name="mobilePhone1"
                              value={formData.mobilePhone1}
                              onChange={handleOnchange}
                            />
                            <span className="hipen">-</span>
                            <input
                              type="text"
                              className="form-control"
                              style={{ width: 100 }}
                              name="mobilePhone2"
                              value={formData.mobilePhone2}
                              onChange={handleOnchange}
                            />
                            <span className="hipen">-</span>
                            <input
                              type="text"
                              className="form-control"
                              style={{ width: 100 }}
                              name="mobilePhone3"
                              value={formData.mobilePhone3}
                              onChange={handleOnchange}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">집주소</label>
                        </th>
                        <td>
                          <div className="row mx-auto mb-1">
                            <input
                              type="text"
                              className="form-control me-75"
                              style={{ width: 100 }}
                              name="zipcode"
                              value={formData.zipcode}
                              readOnly
                            />
                            <button
                              type="button"
                              className="btn btn-outline-primary waves-effect w-auto ms-75"
                              onClick={() => {
                                setFindPostcode({
                                  fn: (formData) => {
                                    setFormData((x) => ({
                                      ...x,
                                      zipcode: formData.zonecode,
                                      address: formData.roadAddress,
                                    }));
                                    setFindPostcode(undefined);
                                  },
                                });
                              }}
                            >
                              우편번호 검색
                            </button>
                          </div>
                          <input
                            type="text"
                            className="form-control my-1"
                            name="address"
                            value={formData.address}
                            onChange={handleOnchange}
                            readOnly
                          />
                          <input
                            type="text"
                            className="form-control"
                            name="addressDetails"
                            value={formData.addressDetails}
                            onChange={handleOnchange}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">
                            개인정보 공개여부<span className="require">*</span>
                          </label>
                        </th>
                        <td>
                          <div className="d-flex align-items-center" onChange={handleOnchange}>
                            <div className="form-check form-check-inline">
                              <label className="form-check-label">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="hideMe"
                                  value=""
                                  checked={!formData.hideMe}
                                />
                                공개
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <label className="form-check-label">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="hideMe"
                                  value="true"
                                  checked={formData.hideMe}
                                />
                                비공개
                              </label>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">
                            사용여부<span className="require">*</span>
                          </label>
                        </th>
                        <td>
                          <div className="d-flex align-items-center" onChange={handleOnchange}>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="state"
                                value="true"
                                checked={formData.state}
                                id={'inlineRadio821'}
                              />
                              <label className="form-check-label" htmlFor="inlineRadio821">
                                사용함
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="state"
                                value=""
                                checked={!formData.state}
                                id={'inlineRadio822'}
                              />
                              <label className="form-check-label" htmlFor="inlineRadio822">
                                사용안함
                              </label>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {formData.type == 'USER' && (
                <>
                  <h4 className="text-primary">추가 입력정보</h4>
                  <div className="mb-75">입력하신 정보는 홈페이지 내 원우정보 메뉴에 노출됩니다.</div>
                  <div className="card">
                    <div className="table-responsive">
                      <table className="table table-bordered visual-box">
                        <colgroup>
                          <col style={{ width: '11rem' }} />
                          <col />
                        </colgroup>
                        <tbody>
                          <tr>
                            <th>
                              <label className="form-label">기업분류</label>
                            </th>
                            <td>
                              <div className="d-flex align-items-center">
                                <select
                                  className="form-select w-auto pe-3 me-75"
                                  // style={{ width: 170 }}
                                  name="workCorpType"
                                  onChange={handleOnchange}
                                  value={formData.workCorpType}
                                >
                                  <option value="">기타</option>
                                  {codes?.tree?.CORP?.map((code) => (
                                    <option value={code.label} key={code.label}>
                                      {code.label}
                                    </option>
                                  ))}
                                </select>
                                {codes?.tree?.CORP?.findIndex((x) => x.label === formData.workCorpType) < 0 && (
                                  <input
                                    type="text"
                                    name="workCorpType"
                                    style={{ width: 200 }}
                                    value={formData.workCorpType}
                                    onChange={handleOnchange}
                                    className="form-control"
                                  />
                                )}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <label className="form-label">직종</label>
                            </th>
                            <td>
                              <div className="d-flex align-items-center">
                                <select
                                  className="form-select w-auto pe-3 me-75"
                                  name="workJobType"
                                  style={{ width: 170 }}
                                  onChange={handleOnchange}
                                  value={formData.workJobType}
                                >
                                  <option value="">기타</option>
                                  {codes?.tree?.JOBS?.map((code) => (
                                    <option key={code.code} value={code.label}>
                                      {code.label}
                                    </option>
                                  ))}
                                </select>
                                {codes?.tree?.JOBS?.findIndex((x) => x.label === formData.workJobType) < 0 && (
                                  <input
                                    type="text"
                                    name="workJobType"
                                    value={formData.workJobType}
                                    onChange={handleOnchange}
                                    className="form-control"
                                    style={{ width: 200 }}
                                  />
                                )}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <label className="form-label">직장명</label>
                            </th>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                style={{ width: 200 }}
                                name="companyName"
                                value={formData.companyName}
                                onChange={handleOnchange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <th>부서</th>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                style={{ width: 200 }}
                                name="companyTeam"
                                value={formData.companyTeam}
                                onChange={handleOnchange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <th>직위</th>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                style={{ width: 200 }}
                                name="companyPosition"
                                value={formData.companyPosition}
                                onChange={handleOnchange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <label className="form-label">직장주소</label>
                            </th>
                            <td>
                              <div className="row mx-auto mb-1">
                                <input
                                  type="text"
                                  className="form-control me-75"
                                  style={{ width: 100 }}
                                  name="zipcode"
                                  value={formData.companyZipcode}
                                  readOnly
                                />
                                <button
                                  type="button"
                                  className="btn btn-outline-primary waves-effect w-auto ms-75"
                                  onClick={() => {
                                    setFindPostcode({
                                      fn: (formData) => {
                                        setFormData((x) => ({
                                          ...x,
                                          companyZipcode: formData.zonecode,
                                          companyAddress: formData.roadAddress,
                                        }));
                                        setFindPostcode(undefined);
                                      },
                                    });
                                  }}
                                >
                                  우편번호 검색
                                </button>
                              </div>
                              <input
                                type="text"
                                className="form-control my-1"
                                name="address"
                                value={formData.companyAddress}
                                onChange={handleOnchange}
                                readOnly
                              />
                              <input
                                type="text"
                                className="form-control"
                                name="companyAddressDetails"
                                value={formData.companyAddressDetails}
                                onChange={handleOnchange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <label className="form-label">해외원우 여부</label>
                            </th>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    // name="inlineR"
                                    id="inlino1"
                                    name="isForeign"
                                    checked={!formData.isForeign}
                                    value="국내"
                                    onClick={() =>
                                      setFormData((x) => ({ ...x, isForeign: false, region1: '', region2: '' }))
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="inlino1"
                                    onClick={() =>
                                      setFormData((x) => ({ ...x, isForeign: false, region1: '', region2: '' }))
                                    }
                                  >
                                    국내
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    //  name="inlineR"
                                    id="inlino2"
                                    name="isForeign"
                                    checked={formData.isForeign}
                                    value="해외"
                                    onClick={() => setFormData((x) => ({ ...x, isForeign: true }))}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="inlino2"
                                    onClick={() => setFormData((x) => ({ ...x, isForeign: true }))}
                                  >
                                    해외
                                  </label>
                                </div>
                                <select
                                  style={{ width: 240 }}
                                  disabled={!formData.isForeign}
                                  name={'region1'}
                                  onChange={handleOnchange}
                                  value={formData?.region1 || ''}
                                  className="form-select w-auto pe-3 ms-1 me-75"
                                >
                                  <option value="">국가를 선택하세요</option>
                                  {codes?.tree?.AREA?.map((code) => (
                                    <option value={code?.code} key={code?.code}>
                                      {code?.label}
                                    </option>
                                  ))}
                                </select>
                                <select
                                  style={{ width: 240 }}
                                  disabled={!formData.isForeign || !regions?.length}
                                  name={'region2'}
                                  onChange={handleOnchange}
                                  value={formData?.region2 || ''}
                                  className="form-select w-auto pe-3"
                                >
                                  <option value="">도시를 선택하세요</option>
                                  {regions?.map((region) => (
                                    <option value={region} key={region}>
                                      {region}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="card">
                    <div className="table-responsive">
                      <table className="table table-bordered visual-box">
                        <thead>
                          <tr>
                            <th>회사소개</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <textarea
                                className="form-control"
                                rows={7}
                                name="companyBio"
                                value={formData.companyBio}
                                onChange={handleOnchange}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="d-flex align-items-end">
                    <h4 className="text-primary col">게시판 글쓰기 권한</h4>
                  </div>
                  <div className="card">
                    <div className="table-responsive">
                      <table className="table table-bordered visual-box">
                        <colgroup>
                          <col style={{ width: '11rem' }} />
                          <col />
                        </colgroup>
                        <tbody>
                          <tr>
                            <th>
                              <label className="form-label">글쓰기 권한</label>
                            </th>
                            <td>
                              <div className="d-flex align-items-center flex-wrap gap-1">
                                {crews.map((item) => {
                                  const value = `${item.type.toUpperCase()}:${item.id}`;
                                  return (
                                    <div className="form-check form-check-inline" key={item.id}>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="inlineR22"
                                        id={`permission${item.id}`}
                                        onChange={(e) => {
                                          const ic = formData.permissions.includes(value);
                                          setFormData((x) => ({
                                            ...x,
                                            permissions: ic
                                              ? x.permissions?.filter((x) => x !== value)
                                              : [...x.permissions, value],
                                          }));
                                        }}
                                        checked={formData.permissions?.includes(value)}
                                      />
                                      <label className="form-check-label" htmlFor={`permission${item.id}`}>
                                        {item.name}
                                      </label>
                                    </div>
                                  );
                                })}
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="inlineR22"
                                    id="inlino212"
                                    onChange={(e) => {
                                      const ic = formData.permissions.includes('CALENDAR');
                                      setFormData((x) => ({
                                        ...x,
                                        permissions: ic
                                          ? x.permissions?.filter((x) => x !== 'CALENDAR')
                                          : [...x.permissions, 'CALENDAR'],
                                      }));
                                    }}
                                    checked={formData.permissions?.includes('CALENDAR')}
                                  />
                                  <label className="form-check-label" htmlFor="inlino212">
                                    IMBA 캘린더
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}

              <div className="col-12 txt-r">
                <button type="submit" className="btn btn-primary me-1 waves-effect waves-float waves-light">
                  저장
                </button>
                <button
                  type="reset"
                  className="btn btn-outline-secondary waves-effect"
                  onClick={() => {
                    navigate('/lms/accounts');
                  }}
                >
                  취소
                </button>
              </div>
              {/* Bordered table end */}
            </div>
          </div>
        </div>
        {/* Bordered table end e */}
      </form>
      {/* // con e */}
      <Modal visible={!!findPostcode} className={'fade text-start modal-primary'}>
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">주소찾기</h5>
              <button type="button" className="btn-close" onClick={() => setFindPostcode(undefined)}></button>
            </div>
            <div className="modal-body">
              <Postcode
                style={{ width: '100%' }}
                jsOptions={{ animation: false, hideMapBtn: true }}
                onSelected={findPostcode?.fn}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
