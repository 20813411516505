import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { restApi } from '../../../common/api';
import PaginationMoon from '../../../components/PaginationMoon';
import { shallowEqual, useSelector } from 'react-redux';
import Modal from '../../../components/Modal';
import produce from 'immer';
import { useLoading } from '../../../nav/AppContainer';

export default function LmsBachelorIndex() {
  const { codes, semesters } = useSelector((s) => s.common, shallowEqual);
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const [params, setParams] = useState(queryString.parse(window.location.search));
  const [keywordType, setKeywordType] = useState();
  const [showSubjectModal, setShowSubjectModal] = useState();

  useEffect(() => {
    setSearchParams(params);
  }, [params]);

  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    const params = queryString.parse(window.location.search);
    const { data } = await restApi.get('/lectures', { params: { ...params } });
    setData(data);
  }, [params]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setParams({
      semesterId: e.target.semesterId.value,
      keyword: e.target.keyword.value,
      keywordType: e.target.keywordType.value,
    });
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">학사관리</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">학사관리</li>
                  <li className="breadcrumb-item active">학사관리</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}
      {/* Bordered table start */}
      <div className="row" id="table-bordered">
        <div className="col-12">
          <form onSubmit={handleSubmit}>
            <div className="card">
              <div className="table-responsive">
                <table className="table table-bordered visual-box">
                  <colgroup>
                    <col style={{ width: '11rem' }} />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        <label className="form-label">년도 / 학기</label>
                      </th>
                      <td>
                        <div className="d-flex align-items-center">
                          <select
                            name="semesterId"
                            defaultValue={''}
                            value={params.semesterId}
                            className="form-select pe-3 me-75"
                            style={{ width: 'auto', maxWidth: '100%' }}
                            onChange={(e) => setParams({ semesterId: e.target.value })}
                          >
                            <option value="">전체</option>
                            {semesters?.map(
                              (item) =>
                                item.id && (
                                  <option value={item.id} key={`${item.id}`}>
                                    {item.year}년 {codes?.map?.[item.session]}
                                  </option>
                                ),
                            )}
                          </select>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label className="form-label">검색어</label>
                      </th>
                      <td>
                        <div className="align-items-center d-flex">
                          <select
                            className="form-select w-auto pe-3"
                            name="keywordType"
                            defaultValue={params.keywordType}
                            onChange={(e) => {
                              setKeywordType(e.target.value);
                            }}
                          >
                            <option value={''}>전체</option>
                            <option value={'mainClass.title'}>과목명</option>
                            <option value={'mainClass.master.name'}>교수</option>
                            <option value={'mainClass.siteType'}>분류</option>
                            <option value={'mainClass.category'}>분야</option>
                          </select>
                          {keywordType === 'mainClass.siteType' ? (
                            <select className="form-select w-auto pe-3 ms-1" name="keyword">
                              <option value={''}>전체</option>
                              {codes?.tree?.MCTP?.map((c) => (
                                <option key={c.code} value={c.code}>
                                  {c.label}
                                </option>
                              ))}
                            </select>
                          ) : keywordType === 'mainClass.category' ? (
                            <select className="form-select w-auto pe-3 ms-1" name="keyword">
                              <option value={''}>전체</option>
                              {codes?.tree?.MCST?.map((c) => (
                                <option key={c.code} value={c.code}>
                                  {c.label}
                                </option>
                              ))}
                            </select>
                          ) : (
                            <input
                              name="keyword"
                              defaultValue={params.keyword}
                              type="text"
                              className="form-control d-sm-inline-block ms-1"
                              style={{ flex: 1 }}
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-12 mt-50 txt-r">
              <button type="submit" className="btn btn-primary waves-effect waves-float waves-light">
                조회
              </button>
            </div>
          </form>
          <div className="card mt-2">
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '6rem' }} />
                  <col />
                  <col style={{ width: '8rem' }} />
                  <col style={{ width: '8rem' }} />
                  <col style={{ width: '12rem' }} />
                  <col style={{ width: '8rem' }} />
                  <col style={{ width: '10rem' }} />
                  <col style={{ width: '7rem' }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>번호</th>
                    <th>과목명</th>
                    <th>교수</th>
                    <th>분류</th>
                    <th>분야</th>
                    <th>년도</th>
                    <th>학기</th>
                    <th>상태</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.content.map((item, index) => (
                    <tr key={item.id}>
                      <td className="txt-c">{data.totalElements - data.size * data.number - index}</td>
                      <td>
                        <Link to={`/lms/bachelor/${item.id}`}>{item.mainClass.title}</Link>
                      </td>
                      <td className="txt-c">{item.mainClass.master.name}</td>
                      <td className="txt-c">
                        {codes?.tree?.MCTP?.find((c) => c.code === item.mainClass.siteType)?.label}
                      </td>
                      <td className="txt-c">
                        {codes?.tree?.MCST?.find((c) => c.code === item.mainClass.category)?.label}
                      </td>
                      <td className="txt-c">{item.semester.year}년</td>
                      <td className="txt-c">{codes?.map?.[item.semester.session]}</td>
                      <td className="txt-c">{item.active ? '운영' : '미운영'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12 txt-r">
            <button
              type="button"
              className="btn btn-primary waves-effect waves-float waves-light me-1"
              onClick={() => {
                if (params.semesterId) {
                  setShowSubjectModal(true);
                } else {
                  alert('복사할 학기를 선택해주세요.');
                }
              }}
            >
              과목복사
            </button>
            <button
              type="submit"
              className="btn btn-primary waves-effect waves-float waves-light"
              onClick={() => {
                navigate('/lms/bachelor/write');
              }}
            >
              등록
            </button>
          </div>
          {/* pagination s */}
          <nav aria-label="Page navigation">
            <PaginationMoon data={data} onClick={(page) => setParams((x) => ({ ...x, page }))} />
          </nav>
          {/* // pagination e */}
        </div>
      </div>
      {/* Bordered table end */}
      {/* // con e */}
      <SubjectModal
        visible={showSubjectModal}
        onExit={() => setShowSubjectModal(false)}
        targetSemesterId={params.semesterId}
      />
    </div>
  );
}

function SubjectModal({ visible, onExit, targetSemesterId }) {
  const { codes, semesters } = useSelector((s) => s.common, shallowEqual);
  const [sourceSemesterId, setSourceSemesterId] = useState();
  const [data, setData] = useState();
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useLoading();

  const isAllChecked = data?.map((v) => v.lectureId).filter((x) => !selected.includes(x)).length === 0;

  const loadData = useCallback(async () => {
    if (visible) {
      const { data } = await restApi.get('/lectures/copy/view/', {
        params: { sourceSemesterId: sourceSemesterId, targetSemesterId: targetSemesterId },
      });
      setData(data);
    }
  }, [visible, sourceSemesterId, targetSemesterId]);

  function onClose() {
    setSourceSemesterId();
    setSelected([]);
    onExit();
  }

  async function handleSubmit() {
    try {
      setLoading(true);
      await restApi.post(`/lectures/copies`, {
        lectureIds: selected,
        semesterId: targetSemesterId,
      });
      setLoading(false);
      alert(`${selected.length}개의 과목 복사가 완료되었습니다.`);
      onClose();
      window.location.reload();
    } catch (error) {
      setLoading(false);
      alert('과목을 다시 확인해주세요.');
      console.error('An error occurred:', error);
    }
  }

  useEffect(() => {
    setSourceSemesterId(semesters[0]?.id === Number(targetSemesterId) ? semesters[1]?.id : semesters[0]?.id);
    // console.log('useEffect', sourceSemesterId);
  }, [visible, semesters, targetSemesterId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Modal className="fade text-start modal-primary" visible={!!visible}>
      <div
        className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
        style={{ maxWidth: 800, width: '90%' }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">과목 복사</h5>
            <button type="button" className="btn-close" onClick={onClose} />
          </div>
          <div className="modal-body">
            <div className="card">
              <div className="card-header">
                <table className="table table-bordered visual-box">
                  <colgroup>
                    <col style={{ width: '11rem' }} />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        <label className="form-label">년도 / 학기</label>
                      </th>
                      <td>
                        <div className="d-flex align-items-center">
                          <select
                            name="semesterId"
                            defaultValue={semesters[0]?.id}
                            value={sourceSemesterId}
                            className="form-select pe-3 me-75"
                            style={{ width: 'auto', maxWidth: '100%' }}
                            onChange={(e) => setSourceSemesterId(e.target.value)}
                          >
                            {semesters
                              .filter((item) => item.id !== Number(targetSemesterId))
                              .map((item) => {
                                return (
                                  item.id && (
                                    <option value={item.id} key={`${item.id}`}>
                                      {item.year}년 {codes?.map?.[item.session]}
                                    </option>
                                  )
                                );
                              })}
                          </select>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table-responsive">
                <table className="table table-bordered visual-box">
                  <thead>
                    <tr>
                      <th>
                        <input
                          className="form-check-input"
                          id=""
                          type="checkbox"
                          checked={isAllChecked}
                          onChange={() => {
                            if (isAllChecked) {
                              setSelected([]);
                            } else {
                              setSelected(data?.map((v) => v.lectureId));
                            }
                          }}
                        />
                      </th>
                      <th>과목명</th>
                      <th>교수</th>
                      <th>분류</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((item) => (
                      <tr key={item.lectureId}>
                        <td className="txt-c">
                          <input
                            className="form-check-input"
                            id=""
                            type="checkbox"
                            checked={selected.includes(item.lectureId)}
                            onChange={() => {
                              setSelected(
                                produce((draft) => {
                                  const ix = draft.indexOf(item.lectureId);
                                  if (ix >= 0) {
                                    draft.splice(ix, 1);
                                  } else {
                                    draft.push(item.lectureId);
                                  }
                                }),
                              );
                            }}
                          />
                        </td>
                        <td>{item.mainClassName}</td>
                        <td className="txt-c">{item.teacherName}</td>
                        <td className="txt-c">{codes?.tree.MCTP.find((c) => c.code === item.siteType)?.label}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-12 mt-2 mb-1 txt-c">
              <button
                type="submit"
                className="btn btn-primary me-1 waves-effect waves-float waves-light"
                onClick={handleSubmit}
              >
                과목복사
              </button>
              <button type="reset" className="btn btn-outline-secondary waves-effect" onClick={onClose}>
                취소
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
