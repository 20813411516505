import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { restApi } from '../../../common/api';
import UserSelect from '../../../components/UserSelect';
import { shallowEqual, useSelector } from 'react-redux';
import LectureSearchItem from '../../../components/LectureSearchItem';
import queryString from 'query-string';

const LmsRecodeWrite = () => {
  const lectureId = useSelector((s) => s.common.lectureId, shallowEqual);
  const navigate = useNavigate();
  const query = queryString.parse(window.location.search);
  const [formData, setFormData] = useState({
    lectureId: query?.lectureId ?? lectureId,
    accountId: query?.accountId ?? undefined,
  });

  const [userData, setUserData] = useState();

  useEffect(() => {
    const loadUserData = async () => {
      const { data } = await restApi.get(`/accounts/${formData.accountId}/bo`);
      setUserData(data);
    };
    loadUserData().catch(console.warn);
  }, [formData.accountId]);

  const [isDelete, setDelete] = useState(false);
  useEffect(() => {
    if (!formData.lectureId || !formData.accountId) {
      setDelete(false);
      return;
    }

    const loadData = async () => {
      setDelete(undefined);
      try {
        await restApi.get(`/lectures/accounts/details`, { params: formData });
        setDelete(true);
      } catch (e) {
        setDelete(false);
      }
    };
    loadData().catch(console.warn);
  }, [formData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.lectureId) {
      alert('과목을 선택해주세요.');
      return;
    } else if (!formData.accountId) {
      alert('학생을 선택해주세요.');
      return;
    }

    const payload = { ...formData };
    if (isDelete) {
      if (!window.confirm('삭제하시겠습니까?')) return;
      await restApi.delete(`/lectures/accounts`, { params: payload });
      alert('삭제되었습니다.');
      await navigate('/lms/record');
    } else {
      await restApi.post(`/lectures/accounts`, payload);
      alert('등록되었습니다.');
      await navigate('/lms/record');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">원우수강이력</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="index.html">Home</a>
                  </li>
                  <li className="breadcrumb-item">원우수강이력</li>
                  <li className="breadcrumb-item active">수강정정 등록</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}
      <div className="row" id="table-bordered">
        <div className="col-12">
          <div className="card">
            <div className="card-header pb-1">
              <h4>수강정정 등록</h4>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered visual-box">
                <colgroup>
                  <col style={{ width: '11rem' }} />
                  <col style={{ width: 'calc(50% - 11rem)' }} />
                  <col style={{ width: '11rem' }} />
                  <col style={{ width: '40%' }} />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      개설년도/개설학기<span className="require">*</span>
                    </th>
                    <td colSpan={3}>
                      <LectureSearchItem
                        value={formData?.lectureId}
                        onChange={(value) => {
                          setFormData((x) => ({ ...x, lectureId: value }));
                        }}
                        noAll={'선택하세요'}
                      />
                    </td>
                  </tr>

                  {/* <tr>
                    <th>
                      <label className="form-label">
                        교과목명<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <select className="form-select me-50" style={{ width: '100%' }}>
                        <option value="">마케팅믹스론</option>
                      </select>
                    </td>
                    <th>
                      <label className="form-label">교과목명(영문)</label>
                    </th>
                    <td>Marketing</td>
                  </tr> */}
                  <tr style={{ borderTop: 0 }}>
                    <th>
                      <label className="form-label">
                        이름/학번<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      {/* <div className="d-flex">
                        <input type="text" className="form-control flex-grow-1 w-auto me-1" defaultValue="" />
                        <button
                          type="submit"
                          className="btn btn-primary waves-effect waves-float waves-light"
                          data-bs-toggle="modal"
                          data-bs-target="#btnChoice"
                        >
                          찾아보기
                        </button>
                      </div> */}
                      <UserSelect
                        title={'찾아보기'}
                        type={'USER'}
                        maxLength={1}
                        name="classMaster"
                        values={[formData.accountId].filter((x) => !!x)}
                        onChange={(v) => {
                          setFormData((x) => ({ ...x, accountId: v[0] }));
                        }}
                      />
                    </td>
                    <th>
                      <label className="form-label">구분</label>
                    </th>
                    <td>{userData?.isLoa ? '휴학생' : userData?.attending ? '재학생' : '졸업생'}</td>
                  </tr>
                  <tr>
                    <th>
                      {/* <label className="form-label">국적/성별</label> */}
                      <label className="form-label">성별</label>
                    </th>
                    <td>{userData?.gender == 'MALE' ? '남' : userData?.gender == 'FEMALE' ? '여' : ''}</td>
                    <th>
                      <label className="form-label">핸드폰</label>
                    </th>
                    <td>{userData?.mobilePhone}</td>
                  </tr>
                  {/* <tr>
                    <th>
                      <label className="form-label">
                        주관대학/주관학과<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <div className="d-flex align-items-center">
                        <select className="form-select me-50" style={{ width: 170 }}>
                          <option value="">경영대학</option>
                        </select>
                        <select className="form-select" style={{ width: 170 }}>
                          <option value="">경영대학원(MBA)</option>
                        </select>
                      </div>
                    </td>
                    <th>
                      <label className="form-label">
                        대표교강사<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <div className="d-flex">
                        <input type="text" className="form-control flex-grow-1 w-auto me-1" defaultValue="" />
                        <button
                          type="submit"
                          className="btn btn-primary waves-effect waves-float waves-light"
                          data-bs-toggle="modal"
                          data-bs-target="#btnChoice"
                        >
                          찾아보기
                        </button>
                      </div>
                    </td>
                  </tr> */}
                  {/* <tr>
                    <th>
                      <label className="form-label">
                        강의시간<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <select className="form-select me-50" style={{ width: 170 }}>
                        <option value="">3</option>
                      </select>
                    </td>
                    <th>
                      <label className="form-label">
                        학점<span className="require">*</span>
                      </label>
                    </th>
                    <td>
                      <select className="form-select me-50" style={{ width: 170 }}>
                        <option value="">3</option>
                      </select>
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12 mt-50 txt-r">
            {isDelete === true ? (
              <button type="submit" className="btn btn-danger me-1 waves-effect waves-float waves-light">
                삭제
              </button>
            ) : isDelete === false ? (
              <button type="submit" className="btn btn-primary me-1 waves-effect waves-float waves-light">
                저장
              </button>
            ) : undefined}
            <button
              type="reset"
              className="btn btn-outline-secondary waves-effect"
              onClick={() => {
                navigate('/lms/record');
              }}
            >
              취소
            </button>
          </div>
        </div>
      </div>
      {/* // con e */}
    </form>
  );
};

export default LmsRecodeWrite;
